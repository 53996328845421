import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import React, { FC, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Popit } from "./popit/popit";

ReactDOM.render(<Popit />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
