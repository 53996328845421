import {
  Canvas,
  extend,
  ReactThreeFiber,
  useLoader,
  useThree,
} from "@react-three/fiber";
import { range } from "lodash";
import React, { FC, Suspense, useState } from "react";
import { Pop } from "./pop";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
//@ts-ignore
import popitmodel from "./popit.glb";
import { MeshStandardMaterial } from "three";
const colors = [
  "#f75917",
  "#d6de5b",
  "#7fdd99",
  "#6bcfe0",
  "#000098",
  "#800080",
];

extend({ OrbitControls });
declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
    }
  }
}

const Objmodel = () => {
  const gltf = useLoader(GLTFLoader, popitmodel);
  const scale = 0.051;
  return (
    <primitive //@ts-ignore
      object={gltf.scene}
      position={[0, -0.65, -0.35]}
      scale={[scale, scale, scale * 1.15]}
      rotation={[Math.PI / 2, 0, 0]}
    />
  );
};

const Scene: FC<{ incPop: () => void }> = ({ incPop }) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  return (
    <>
      <Suspense fallback={null}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <mesh onClick={(e) => {}}>
          <boxGeometry args={[8, 8, 0.2]} />
          <meshStandardMaterial color={"orange"} opacity={0} alphaTest={1} />
        </mesh>
        <Objmodel />
        {range(6).map((el) => {
          const i = el - 3;
          return range(6).map((el) => {
            const j = el - 3;

            return (
              <Pop
                key={el}
                incPop={incPop}
                color={colors[5 - el]}
                position={[(i + 0.5) * 1.2, (j + 0.5) * 1.2, 0]}
              />
            );
          });
        })}
        {}
        <orbitControls args={[camera, domElement]} />
      </Suspense>
    </>
  );
};

export const Popit = () => {
  const [popCount, setPopCount] = useState(0);
  const incPop = () => {
    setPopCount(popCount + 1);
  };

  return (
    <div className="popItApp">
      <Canvas>
        <Scene incPop={incPop} />
      </Canvas>
      <div className="popCount">{popCount}</div>
    </div>
  );
};
