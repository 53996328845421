import { useFrame, Vector3 } from "@react-three/fiber";
import { FC, useRef, useState } from "react";
import * as THREE from "three";

export const Pop: FC<{ incPop: () => void; color: string; position: Vector3 }> =
  ({ incPop, color, position }) => {
    // This reference will give us direct access to the THREE.Mesh object
    const mesh = useRef<any>();
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false);
    const [active, setActive] = useState(false);

    // Subscribe this component to the render-loop, rotate the mesh every frame
    // Return the view, these are regular Threejs elements expressed in JSX
    return (
      <mesh
        position={position}
        ref={mesh}
        scale={active ? 1.1 : 1}
        onPointerDown={(event) => {
          console.log(
            event.intersections[0].object.uuid,
            event.intersections[0].object.uuid == mesh.current.uuid,
            mesh.current.uuid
          );
          if (event.intersections[0].object.uuid == mesh.current.uuid) {
            if (active) {
              var audio = new Audio("pop0.wav");
              audio.play();
              incPop();
            }
            setActive(!active);
          }
        }}
        onClick={(event) => {
          console.log(
            event.intersections[0].object.uuid,
            event.intersections[0].object.uuid == mesh.current.uuid,
            mesh.current.uuid
          );
          if (event.intersections[0].object.uuid == mesh.current.uuid) {
            if (active) {
              var audio = new Audio("pop0.wav");
              audio.play();
            }
            setActive(!active);
          }
        }}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
      >
        <sphereGeometry
          args={
            active
              ? [0.55, 10, 10, Math.PI, Math.PI]
              : [0.55, 10, 10, 0, Math.PI]
          }
        />
        <meshStandardMaterial
          opacity={hovered ? 0.9 : 1}
          color={color ?? "orange"}
          side={THREE.DoubleSide}
        />
      </mesh>
    );
  };
